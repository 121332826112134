import "../styles/globals.css";
import "../styles/newStyle.css";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.min.css";
import Layout from "../components/layout/layout-main/layout";
import TagManager from "react-gtm-module";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import Cookies from "js-cookie";
import 'animate.css';
import { isMobile } from "react-device-detect";

// import * as ga from '../lib/ga'
const tagManagerArgs = {
  gtmId: "GTM-TJTGF26",
};


function MyApp({ Component, pageProps }) {


  const router = useRouter();
  const [finishStatus, setfinishStatus] = useState(false);

  useEffect(() => {
    if (isMobile === true) {
      FreshworksWidget("destroy");
    }
    const localItems = [
      "selected-location",
      "membership",
      "insurance-val",
      "patientAddress",
    ];

    localItems.forEach((element) => {
      window.localStorage.removeItem(element);
    });

    if (router.asPath === "/checkout") {
      if (Cookies.get("cart") == undefined) {
        router.push("/")
      }
    }
  }, []);
  // const onBackButtonEvent = (e) => {
  //   e.preventDefault();
  //   if (!finishStatus) {
  //     window.history.pushState(null, null, window.location.pathname);
  //     // your logic
  //   }
  // };

  // useEffect(() => {
  //   window.history.pushState(null, null, window.location.pathname);
  //   window.addEventListener("popstate", onBackButtonEvent);
  //   return () => {
  //     window.removeEventListener("popstate", onBackButtonEvent);
  //   };
  // }, []);
  const [count, setCount] = useState();
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  });
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || [];
    window &&
      window.dataLayer?.push({
        event: "pageview",
      });

    return (
      <Layout count={count}>
        <Component {...pageProps} setCount={setCount} />
      </Layout>
    );
  } else {
    return null;
  }
}

export default MyApp;
